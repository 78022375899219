export const slides = [
    {
        image: "/images/blossom-8182139_1280.jpg",
        title: "Blossom",
    }, 
    {
        image: "/images/corn-8028831_1280.jpg", 
        title: "Corn", 
    }, 
    {
        image: "/images/flower-8145571_1280.jpg", 
        title: "Flower", 
    }, 
    {
        image: "/images/swan-8192196_1280.jpg", 
        title: "Swan",
    },
];